import React, { useState, ReactNode, useCallback } from 'react'
import Button from '@/ui/atoms/Button'
import Modal from '@/ui/molecules/Modal'

type ConfirmPopProps = {
  title: string
  description: string
  confirmButtonText?: string
  cancelButtonText?: string
  onConfirm: () => void | Promise<void>
  onCancel?: () => void
  disabled?: boolean
  children: ReactNode
  variantConfirmButton?:
    | 'default'
    | 'secondary'
    | 'destructive'
    | 'neutral'
    | 'ghost'
}

const ConfirmPop: React.FC<ConfirmPopProps> = ({
  title,
  description,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  onConfirm,
  onCancel,
  children,
  disabled = false,
  variantConfirmButton = 'destructive'
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const openModal = useCallback(() => {
    if (!disabled) {
      setIsOpen(true)
    }
  }, [disabled])

  const closeModal = useCallback(() => {
    setIsOpen(false)
    if (onCancel) {
      onCancel()
    }
  }, [onCancel])

  const handleConfirm = useCallback(async () => {
    setIsLoading(true)
    try {
      await onConfirm()
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }, [closeModal, onConfirm])

  return (
    <>
      <div onClick={openModal} className="w-fit h-fit">
        {children}
      </div>
      <Modal isOpen={isOpen} title={title} onClose={closeModal}>
        <div className="mt-2">
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <div className="flex justify-end w-full gap-3 mt-4">
          <Button variant="neutral" size="sm" onClick={closeModal}>
            {cancelButtonText}
          </Button>
          <Button
            variant={variantConfirmButton}
            size="sm"
            onClick={handleConfirm}
            loading={isLoading}
          >
            {confirmButtonText}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmPop
