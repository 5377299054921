'use client'

import { useMemo } from 'react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import { useViewport } from '@/hooks/useViewport'
import { cn } from '@/lib/utils'
import Typography from '@/ui/atoms/Typography'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/ui/shadcn/dialog'
import * as Drawer from '@/ui/shadcn/drawer'

export type ModalProps = {
  isOpen: boolean
  onClose?: () => void
  title?: string
  children: React.ReactNode
  hiddenHeader?: boolean
  classNames?: {
    containerHeader?: string
    container?: string
    content?: string
  }
  displayAsDrawerOnMobile?: boolean
  maxHeightDrawer?: string
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  hiddenHeader = false,
  classNames,
  displayAsDrawerOnMobile = false,
  maxHeightDrawer = '80vh'
}) => {
  const { breakpoint } = useViewport()

  const shouldRenderAsDrawer = useMemo(() => {
    return (
      displayAsDrawerOnMobile && (breakpoint === 'xs' || breakpoint === 'sm')
    )
  }, [breakpoint, displayAsDrawerOnMobile])

  if (shouldRenderAsDrawer) {
    return (
      <Drawer.Drawer
        open={isOpen}
        onOpenChange={(boolean) => {
          if (!boolean) {
            onClose?.()
          }
        }}
      >
        <Drawer.DrawerContent
          className={cn(
            `bg-gray-50 p-4 max-h-[${maxHeightDrawer}]`,
            classNames?.container
          )}
        >
          {!hiddenHeader && (
            <div
              className={cn(
                'flex justify-between items-start pb-2 border-b border-gray-200',
                classNames?.containerHeader
              )}
            >
              <Typography variant="title-xl-regular" className="font-display">
                {title}
              </Typography>
            </div>
          )}
          <div
            className={cn(
              'relative overflow-auto',
              !hiddenHeader && 'pt-4',
              classNames?.content
            )}
          >
            {children}
          </div>
        </Drawer.DrawerContent>
      </Drawer.Drawer>
    )
  }

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(boolean) => {
        if (!boolean) {
          onClose?.()
        }
      }}
    >
      <DialogContent
        className={cn('p-6 [&>button]:hidden', classNames?.container)}
        aria-describedby={undefined}
      >
        {!hiddenHeader && (
          <DialogHeader
            className={cn(
              'flex justify-between items-start p-1 pt-1 pb-2 pl-1 min-h-[45px] h-auto border-b border-gray-200',
              classNames?.containerHeader
            )}
          >
            <DialogTitle className="text-xl font-medium tracking-normal font-display text-md sm:text-lg md:text-xl">
              {title}
            </DialogTitle>
            <DialogClose />
          </DialogHeader>
        )}
        {hiddenHeader && !!title && (
          <VisuallyHidden>
            <DialogTitle>{title}</DialogTitle>
          </VisuallyHidden>
        )}
        <div
          className={cn(
            'px-0.5 pt-4 pb-0.5 w-full overflow-auto max-h-[85vh]',
            classNames?.content
          )}
        >
          {children}
        </div>
      </DialogContent>
    </Dialog>
  )
}

type ModalFooterActionsProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  align?: 'between' | 'end' | 'start'
  className?: string
}

export const ModalFooterActions: React.FC<ModalFooterActionsProps> = ({
  children,
  className,
  align = 'end'
}) => {
  const classNameAlign = {
    between: 'justify-between',
    end: 'justify-end',
    start: 'justify-start'
  }[align]

  return (
    <DialogFooter
      className={cn(
        'flex justify-end gap-4 pt-6 border-t border-gray-200 flex-row',
        classNameAlign,
        className
      )}
    >
      {children}
    </DialogFooter>
  )
}

export default Modal
