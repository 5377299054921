import { Lang } from '@/types/global'
import {
  EnvelopeStatus,
  PageSizeEnvelopeType,
  ShouldEnforceValidationType
} from '../../ecm/useEnvelopeApi/useEnvelopeApi.types'

export type ApproveSignMarkRequest = {
  signatureLinkId: string
  signMarkId: string
  recaptchaToken: string
}

export type HtmlToPdfRequest = {
  html: string
}

export enum ActionType {
  View = 'View',
  Approval = 'Approval'
}

export type Action = {
  id: string
  createdAtUtc: string
  type: keyof typeof ActionType
  ip: string
  userAgent: string
  location: string
}

export enum SignerStatus {
  None = 'None',
  ReadyToReview = 'ReadyToReview',
  Queued = 'Queued',
  Approved = 'Approved',
  Rejected = 'Rejected',
  CancelledByMfaError = 'CancelledByMfaError'
}

export type Signer = ShouldEnforceValidationType & {
  id: string
  index: number
  status: keyof typeof SignerStatus
  hadApproved: boolean
  approvedAtUtc: string
  hadRejected: boolean
  rejectedAtUtc: string | null
  name: string
  email: string
  role: string
  title: string | null
  actions: Action[]
}

export type DocumentType = {
  id: string
  index: number
  name: string
  fileName: string
  description: string | null
  pageSizes: PageSizeEnvelopeType[]
}

export type GetEnvelopeRequest = {
  recaptchaToken: string | null
}

export type GetEnvelopeRequestWithId = GetEnvelopeRequest & {
  id: string
}

export type GetEnvelopeResponse = {
  id: string
  createdAtUtc: string
  createdById: string
  createdByName: string
  createdByAvatar: string | null
  updatedAtUtc: string
  lastUpdateById: string
  updatedByName: string
  updatedByAvatar: string | null
  userAccountOwnerId: string
  userAccountOwnerName: string
  userAccountOwnerAvatar: string | null
  language: Lang
  status: keyof typeof EnvelopeStatus
  name: string
  description: string | null
  signInOrder: boolean
  message: string | null
  expiresAtUtc: string | null
  certifiedAtUtc: string
  isSandbox: boolean
  hasActionsBeingProcessed: boolean
  documents: DocumentType[]
  signers: Signer[]
}

export type DocumentDownloadParams = {
  id: string
  name: string
}
